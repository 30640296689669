import React from "react";
import logo from "./unicorn.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div class="stars"></div>
      <div class="twinkling"></div>
      <div class="clouds"></div>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>miguel is lief</p>
        <p>en linde is ook lief ❤️</p>
      </header>
    </div>
  );
}

export default App;
